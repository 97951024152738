import produce from 'immer'
import { WritableDraft } from 'immer/dist/types/types-external'
import { groupBy } from '../../shared/array'
import { createContextReducer } from '../../shared/context-reducer'
import { fail, spliceItem } from '../../shared/typescript'

export interface LoadState {
  view: `home` | `details`
  outputText: string
  outputPending: LoadPending | null
  outputRejected: LoadRejected | null
  outputAccepted: LoadOutputAccepted | null
  inputText: string
  inputPending: Array<LoadPending>
  inputRejected: Array<LoadRejected>
  inputAccepted: Array<LoadAccepted>
  inputSpecs: Array<LoadSpec>
  hasBlockingError: { title: string; message: string; hasError: boolean }
}

export interface LoadPending {
  text: string
  trackingId?: string
  itemId?: string
  warehouseTrackingCode?: string | null
  variantCode?: string | null
  quantity?: number
  status?: string
}

export interface LoadRejected {
  text: string
  reason: string
  reasonArgs?: { [key: string]: string }
}

export interface LoadOutputAccepted {
  text: string
  carrierId: string
  workItemId: string
}

export interface LoadAccepted {
  text: string
  trackingId: string
  itemId: string
  variantCode: string | null
  quantity: number
  quantityUnit: string
  warehouseTrackingCode: string
  positionId: string | null
  targetTaskId: string
  targetTaskResultId: string
}

export interface LoadSpec {
  taskId: string
  taskResultId: string
  itemId: string
  variantCode: string | null
  quantity: number
  quantityUnit: string
  warehouseTrackingCode: string
  positionId: string | null
}

const emptyLoadState: LoadState = {
  view: `home`,
  outputText: ``,
  outputPending: null,
  outputRejected: null,
  outputAccepted: null,
  inputText: ``,
  inputPending: [],
  inputRejected: [],
  inputAccepted: [],
  inputSpecs: [],
  hasBlockingError: { title: '', message: '', hasError: false },
}

export type LoadAction =
  | { type: `reset` }
  | { type: `view-home` }
  | { type: `view-details` }
  | { type: `change-output`; text: string }
  | { type: `admit-output`; text: string }
  | {
      type: `accepted-output`
      carrierId: string
      text: string
      workItemId: string
      inputAccepted: Array<LoadAccepted>
      inputSpecs: Array<LoadSpec>
    }
  | { type: `rejected-output`; text: string; reason: string }
  | { type: `change-input`; text: string }
  | {
      type: `admit-input`
      text: string
      itemId: string
      variantCode: string | null
      quantity: number
      warehouseTrackingCode: string | null
      status: string
    }
  | {
      type: `accepted-input`
      text: string
      trackingId: string
      itemId: string
      positionId: string | null
      variantCode: string | null
      targetTaskId: string
      targetTaskResultId: string
      quantity: number
      warehouseTrackingCode: string
      quantityUnit: string
    }
  | { type: `rejected-input`; text: string; reason: string; reasonArgs?: { [key: string]: string } }
  | { type: `reset-blocking-error` }

type LoadActionReducerMap = {
  [key in LoadAction['type']]: (state: WritableDraft<LoadState>, action: LoadAction & { type: key }) => LoadState | void
}

const actionReducers: LoadActionReducerMap = {
  'reset-blocking-error'(state) {
    state.hasBlockingError = { title: '', message: '', hasError: false }
  },
  reset() {
    return emptyLoadState
  },
  'view-home'(state) {
    state.view = `home`
  },
  'view-details'(state) {
    state.view = `details`
  },

  'change-output'(state, { text }) {
    Object.assign(state, emptyLoadState)
    state.outputText = text
  },
  'admit-output'(state, { text }) {
    if (text) {
      Object.assign(state, emptyLoadState)
      state.outputText = text
      state.outputPending = { text }
    }
  },
  'accepted-output'(state, { text, carrierId, workItemId, inputAccepted, inputSpecs }) {
    if (text) {
      state.outputRejected = null
      state.outputPending = null
      state.outputAccepted = {
        text,
        carrierId,
        workItemId,
      }
      state.inputAccepted = inputAccepted
      state.inputSpecs = inputSpecs // combine lines and sum up qty
    }
  },
  'rejected-output'(state, { text, reason }) {
    if (text) {
      state.outputAccepted = null
      state.outputRejected = { text, reason }
      state.hasBlockingError.title = text
      state.hasBlockingError.message = reason
      state.hasBlockingError.hasError = true
    }
  },
  'change-input'(state, { text }) {
    state.inputText = text
  },
  'admit-input'(state, { text, itemId, quantity, variantCode, warehouseTrackingCode, status }) {
    if (text) {
      // Comment spliceItem because it causes state change and duplication
      // spliceItem(state.inputPending, (x) => x.text === text)
      const existingInput = state.inputPending.findIndex(x => x.text === text)
      if (existingInput === -1) {
        state.inputPending.push({ text, itemId, quantity, variantCode, warehouseTrackingCode, status })
      }
    }
  },
  'accepted-input'(
    state,
    {
      itemId,
      targetTaskId,
      targetTaskResultId,
      text,
      trackingId,
      variantCode,
      quantity,
      warehouseTrackingCode,
      positionId,
      quantityUnit,
    },
  ) {
    if (text) {
      spliceItem(state.inputPending, x => x.text === text)
      spliceItem(state.inputAccepted, x => x.text === text)
      spliceItem(state.inputRejected, x => x.text === text)

      state.inputAccepted.push({
        itemId,
        targetTaskId,
        targetTaskResultId,
        text,
        trackingId,
        variantCode,
        quantity,
        positionId,
        quantityUnit,
        warehouseTrackingCode,
      })
    }
  },
  'rejected-input'(state, { text, reason, reasonArgs }) {
    if (text) {
      // remove entry from all states, but leave accepted as is
      spliceItem(state.inputPending, x => x.text === text)
      spliceItem(state.inputRejected, x => x.text === text)
      // state.hasBlockingError.title = text
      // state.hasBlockingError.message = reason
      // state.hasBlockingError.hasError = true
      state.inputRejected.push({ text, reason, reasonArgs })
    }
  },
}

const reducer = (prevState: LoadState, action: LoadAction) => {
  const state = produce(prevState, draft => {
    // Find matching action reducer
    const actionReducer = actionReducers[action.type] as (
      state: WritableDraft<LoadState>,
      action: LoadAction,
    ) => LoadState | void

    // Apply reducer to current state
    draft = actionReducer(draft, action) || draft

    // Return new state
    return draft
  })
  return state
}

export const LoadReducer = createContextReducer(`LoadReducer`, `__LOAD`, reducer, emptyLoadState)

export namespace Selectors {
  export function isFulfilled(accepted: LoadAccepted[]): (value: LoadSpec) => unknown {
    return s => {
      const input = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(accepted)
        .filter(
          x =>
            x.itemId === s.itemId &&
            x.variantCode === s.variantCode &&
            x.warehouseTrackingCode === s.warehouseTrackingCode,
        )
        .filter(MatchInputsBySpec(s))
      return s.quantity === input.reduce((acc, n) => acc + n.quantity, 0)
    }
  }

  export function isOverloaded(accepted: LoadAccepted[]): (value: LoadSpec) => unknown {
    return s => {
      const input = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(accepted)
        .filter(
          x =>
            x.itemId === s.itemId &&
            x.variantCode === s.variantCode &&
            x.warehouseTrackingCode === s.warehouseTrackingCode,
        )
        .filter(MatchInputsBySpec(s))
      return s.quantity < input.reduce((acc, n) => acc + n.quantity, 0)
    }
  }

  export function MatchInputsBySpec(s: LoadSpec) {
    return (a: LoadAccepted) =>
      a.targetTaskResultId === s.taskResultId &&
      a.itemId === s.itemId &&
      a.variantCode === s.variantCode &&
      a.warehouseTrackingCode === s.warehouseTrackingCode &&
      a.positionId === s.positionId &&
      a.quantityUnit === s.quantityUnit
  }

  export function MatchSpecsByItem(
    itemId: number,
    variantCode: string | null,
    warehouseTrackingCode: string,
  ): (value: LoadSpec | LoadAccepted | LoadPending) => unknown {
    if (variantCode && variantCode.trim() !== '') {
      return x =>
        x.itemId!.toString() === itemId.toString() &&
        x.variantCode === variantCode &&
        x.warehouseTrackingCode === warehouseTrackingCode
    } else {
      return x => x.itemId!.toString() === itemId.toString() && x.warehouseTrackingCode === warehouseTrackingCode
    }
  }

  export function simplifyInputsByCombiningDuplicateItems(
    inputs: Array<{
      taskResultId: string
      taskId: string
      quantity: number
      itemId: string
      quantityUnit: string
      variantCode: string | null
      warehouseTrackingCode: string | null
      positionId: string | null
    }>,
  ): LoadSpec[] {
    return groupBy(inputs, x =>
      [x.itemId, x.variantCode, x.quantity, x.quantityUnit, x.warehouseTrackingCode, x.taskResultId, x.positionId].join(
        `;`,
      ),
    ).map(
      (x): LoadSpec => ({
        taskResultId: x[0]!.taskResultId,
        taskId: x[0]!.taskId,
        quantity: x.reduce((acc, n) => acc + n.quantity, 0),
        itemId: x[0]!.itemId,
        quantityUnit: x[0]!.quantityUnit,
        variantCode: x[0]!.variantCode,
        positionId: x[0]!.positionId,
        warehouseTrackingCode: x[0]!.warehouseTrackingCode ?? fail(`expects warehouseTrackingCode`),
      }),
    )
  }
  export function simplifyAcceptedInputsByCombiningDuplicateItems(
    inputs: Array<{
      text: string
      trackingId: string
      targetTaskResultId: string
      targetTaskId: string
      quantity: number
      itemId: string
      quantityUnit: string | null
      variantCode: string | null
      warehouseTrackingCode: string
      positionId: string | null
    }>,
  ): LoadAccepted[] {
    return groupBy(inputs, x =>
      [
        x.itemId,
        x.variantCode,
        x.quantityUnit,
        x.quantity,
        x.warehouseTrackingCode,
        x.targetTaskResultId,
        x.positionId,
      ].join(`;`),
    ).map(
      (x): LoadAccepted => ({
        targetTaskResultId: x[0]!.targetTaskResultId,
        targetTaskId: x[0]!.targetTaskId,
        quantity: x.reduce((acc, n) => acc + n.quantity, 0),
        quantityUnit: x[0]?.quantityUnit!,
        itemId: x[0]!.itemId,
        variantCode: x[0]!.variantCode,
        text: x[0]?.text!,
        trackingId: x[0]?.trackingId!,
        positionId: x[0]?.positionId!,
        warehouseTrackingCode: x[0]?.warehouseTrackingCode!,
      }),
    )
  }
}

export namespace LoadSelectors {
  export function rejectedInputs(state: LoadState) {
    return state.inputRejected
  }

  export function acceptedInputs(state: LoadState) {
    return state.inputAccepted
  }

  export function pendingInputs(state: LoadState) {
    return state.inputPending
  }

  export function pendingOutputs(state: LoadState) {
    return state.outputPending
  }

  export function acceptedOutput(state: LoadState) {
    return state.outputAccepted
  }

  export function rejectedOutput(state: LoadState) {
    return state.outputRejected
  }
}
