import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Box, Divider } from '@mui/material'
import { MText } from '@mprise/react-ui'
import { UNLOCK_CARRIER } from '../../gql/load'

export const LoadCompletedPopup = ({
  completed,
  onReset,
  outputText,
  carrierId,
}: {
  outputText: string
  completed: boolean
  onReset: () => void
  carrierId?: number | null
}) => {
  const [unlockCarrier] = useMutation(UNLOCK_CARRIER)

  const { t } = useTranslation()

  useEffect(() => {
    if (completed) {
      document.addEventListener('touchstart', onReset)
      document.addEventListener('keydown', onReset)

      if (carrierId) {
        unlockCarrier({
          variables: {
            carrierId: carrierId,
          },
        })
      }

      return () => {
        document.removeEventListener('keydown', onReset)
        document.removeEventListener('touchstart', onReset)
      }
    }
  }, [completed])

  if (!completed) return <></>

  return (
    <Box sx={{ position: 'fixed', inset: 0, background: 'rgba(0, 0, 0, 0.15)' }}>
      <Box sx={{ background: 'white', padding: '2rem', margin: '4rem 1rem', textAlign: 'center' }}>
        <MText block textVariant='content bold'>
          {t('CARRIER_COMPLETED', { code: outputText })}
        </MText>
        <MText block textVariant='small' style={{ marginTop: '8px' }}>
          {t('TAP_TO_CONTINUE')}
        </MText>
        <Divider sx={{ background: '#40b67f', height: '1.5px' }} />
      </Box>
    </Box>
  )
}
