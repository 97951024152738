import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { MAudio } from '@mprise/react-ui/dist/audio'
import { BlockingMessage } from '../../mprise-light/blocking-message'
import { Card } from '../../mprise-light/card'
import { Counter } from '../../mprise-light/counter'
import { Field } from '../../mprise-light/field'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { Flex } from '../../mprise-light/flex'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText, StatusValue } from '../../mprise-light/status-text'
import { CollapseWrapper } from '../../shared/collapse-wrapper'
import { useAsync } from '../../shared/use-async'
import { useHistory } from '../../shared/use-history'
import { not, fail } from '../../shared/typescript'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { useAppSettingsContext } from '../../context/AppSettingsContext'
import { LoadOutputDetails } from './output-details'
import { LoadReceipt } from './receipt'
import { LoadAccepted, LoadAction, LoadReducer, LoadSelectors, LoadSpec, LoadState, Selectors } from './reducer'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { LOAD_INPUT, LOAD_OUTPUT, UNLOCK_CARRIER } from '../../gql/load'
import { parseError } from '../../shared/errors'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { ClearFieldButton } from '../../shared/clear-field-button'

export const LoadRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { currentCompany, defaultResource: currentResource } = useAppSettingsContext()
  const companyId = currentCompany?.id!

  const [state, dispatch] = LoadReducer.useReducer()

  const outputTrackingId = useBarcodeInput('outputTrackingId', 'inputTrackingId')
  const inputTrackingId = useBarcodeInput('inputTrackingId')

  const [getTracking] = useLazyQuery(GET_TRACKING_ID)
  const [loadInputScan] = useMutation(LOAD_INPUT)
  const [loadOutputScan] = useMutation(LOAD_OUTPUT)
  const [unlockCarrier] = useMutation(UNLOCK_CARRIER)

  const handleCancel = () => {
    handleReset()
    h.push('/')
  }

  const handleHome = () => {
    dispatch({ type: 'view-home' })
  }

  const handleReset = () => {
    const carrierId = state.outputAccepted?.carrierId
    if (carrierId) {
      unlockCarrier({
        variables: {
          carrierId: carrierId,
        },
      })
    }

    dispatch({ type: 'reset' })
    setTimeout(() => outputTrackingId.focus(), 0)
  }

  const alerts = FlashAlerts.useAlert()

  const dispatchWithAlert = (action: LoadAction) => {
    dispatch(action)

    if (action.type === 'rejected-input') {
      alerts.error(t(action.reason, action.reasonArgs))
      MAudio.scanError()
    }
  }

  const [firstPendingTrackingId] = state.inputPending

  useAsync(
    async abort => {
      if (abort.aborted) {
        return
      }

      if (firstPendingTrackingId && hasUnfulfilled) {
        const currentResourceId = currentResource?.id ?? fail('requires current resource to be set')
        const outputAccepted = state.outputAccepted ?? fail('requires accepted output')

        const { text, variantCode, quantity, itemId, warehouseTrackingCode, status } = firstPendingTrackingId

        const isAlreadyPending = state.inputPending.filter(x => x.text === text).length > 1
        const isAlreadyScanned = state.inputAccepted.some(x => x.text === text)

        try {
          if (status && status !== 'AVAILABLE') {
            dispatchWithAlert({
              type: 'rejected-input',
              text,
              reason: 'NOTIFICATION_WRONG_STATUS_TRACKING_ID',
              reasonArgs: { status: t(`TRACKING_STATUS.${status}`) },
            })
            return
          }

          if (isAlreadyScanned || isAlreadyPending) {
            dispatchWithAlert({
              type: 'rejected-input',
              text,
              reason: 'NOTIFICATION_ALREADY_SCANNED',
            })
            return
          }

          // get current scanned amount for this tid
          // const { itemId, variantCode, quantity, warehouseTrackingCode  } = trackingId!
          const matchingSpecs = state.inputSpecs.filter(
            Selectors.MatchSpecsByItem(+itemId!, variantCode ?? null, warehouseTrackingCode!),
          )

          if (matchingSpecs.length === 0) {
            dispatchWithAlert({
              type: 'rejected-input',
              text: text,
              reason: 'NOTIFICATION_WRONG_ITEM_OR_VARIANT_OR_WHTCODE',
            })
            return
          }

          // do we still have space? Take into account pending scans.
          const space = matchingSpecs.find(s => {
            const otherPendingInputs = state.inputPending.filter(x => x.text !== text)
            const used = [...state.inputAccepted, ...otherPendingInputs].filter(
              Selectors.MatchSpecsByItem(+itemId!, variantCode ?? null, warehouseTrackingCode!),
            )
            const usedQuantity = used.reduce((acc, n) => acc + n.quantity!, 0)
            const reserveQuantity = usedQuantity + quantity!
            return reserveQuantity <= s.quantity
          })

          // if space continue.
          if (!space) {
            // get quantity we have.
            const maxQuantity = state.inputSpecs.find(
              Selectors.MatchSpecsByItem(+itemId!, variantCode ?? null, warehouseTrackingCode!),
            )?.quantity
            dispatchWithAlert({
              type: 'rejected-input',
              text: text,
              reason: 'NOTIFICATION_TOO_MANY',
              reasonArgs: { count: `${maxQuantity}` },
            })
            return
          }

          loadInputScan({
            variables: {
              input: {
                carrierIdId: outputAccepted.carrierId,
                companyId: +companyId,
                resourceId: +currentResourceId,
                trackingIdCode: text,
                workItemId: outputAccepted.workItemId,
              },
            },
          })
            .then(response => {
              dispatch({
                type: 'accepted-input',
                text: text,
                ...(response.data.loadInputScan as Omit<LoadAccepted, 'text'>),
                quantityUnit: space.quantityUnit,
                warehouseTrackingCode: space.warehouseTrackingCode, // we can also change the result to provide the whtcode, but this is convenient.
                positionId: space.positionId,
              })

              MAudio.scanSuccess()
              alerts.success(t('NOTIFICATION_OUTPUT_ACCEPTED'))
            })
            .catch(e => {
              const { errorMessage, messageArgs } = parseError(e)
              dispatchWithAlert({
                type: 'rejected-input',
                text: text,
                reason: errorMessage,
                reasonArgs: messageArgs,
              })
            })
        } catch (ex) {
          dispatchWithAlert({
            type: 'rejected-input',
            text: text,
            reason: 'NOTIFICATION_ERROR_X',
            reasonArgs: { x: (ex as any).message },
          })
        }
      }
    },
    [firstPendingTrackingId],
  )

  const handleBlockingErrorClick = () => {
    if (state.outputAccepted?.text) {
      dispatch({ type: 'change-input', text: '' })
      inputTrackingId.focus()
    } else {
      dispatch({ type: 'change-output', text: '' })
      outputTrackingId.focus()
    }
    dispatch({ type: 'reset-blocking-error' })
  }

  const inputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(state.inputSpecs)
  const inputAccepted = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(state.inputAccepted)

  const hasUnfulfilled = inputSpecs.some(not(Selectors.isFulfilled(inputAccepted)))

  const handleOutputAction = async (outputText: string) => {
    try {
      if (outputText) {
        const currentResourceId = currentResource?.id ?? fail('requires current resource to be set')

        await loadOutputScan({
          variables: {
            input: {
              companyId: +companyId,
              carrierIdCode: outputText,
              resourceId: +currentResourceId,
            },
          },
        }).then(response => {
          const result = response?.data?.loadOutputScan
          const inputSpecs = result?.inputSpecs! as LoadSpec[]
          const inputAccepted = result?.inputAccepted! as LoadAccepted[]

          const dedupedInputAccepted = Selectors.simplifyAcceptedInputsByCombiningDuplicateItems(inputAccepted)
          const dedupedInputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(inputSpecs)
          const isFulfilled = dedupedInputSpecs.every(Selectors.isFulfilled(dedupedInputAccepted))

          // Change already loaded CarrierID to rejected-output and give error notification
          if (isFulfilled) {
            outputTrackingId.onBadInput(outputText)
            alerts.error(t('NOTIFICATION_CARRIER_ALREADY_LOADED'))

            dispatch({
              text: outputText,
              type: 'rejected-output',
              reason: t('NOTIFICATION_CARRIER_ALREADY_LOADED'),
            })
          } else {
            outputTrackingId.onGoodInput(outputText)

            dispatch({
              type: 'accepted-output',
              inputSpecs: result?.inputSpecs! as LoadSpec[],
              inputAccepted: result?.inputAccepted! as LoadAccepted[],
              text: outputText,
              carrierId: result?.carrierId as string,
              workItemId: result?.workItemId as string,
            })

            alerts.success(t('NOTIFICATION_OUTPUT_ACCEPTED'))
          }
        })
      }
    } catch (e: any) {
      const { errorMessage, messageArgs } = parseError(e)

      alerts.error(t(errorMessage, messageArgs))
      MAudio.scanError()

      dispatch({ type: 'change-output', text: '' })
      outputTrackingId.focus()
    }
  }

  const handleInputAction = async (inputText: string) => {
    if (!inputText) {
      return
    }

    await getTracking({
      variables: {
        filter: {
          companyId: +companyId,
          code: inputText,
        },
      },
      fetchPolicy: 'no-cache',
    }).then(result => {
      if (result?.data?.trackingId) {
        const { item, quantity, variantCode, warehouseTrackingCode, status } = result?.data?.trackingId

        dispatch({
          type: 'admit-input',
          itemId: item?.id!,
          quantity: quantity ?? 0,
          text: inputText,
          variantCode: variantCode ?? '',
          warehouseTrackingCode: warehouseTrackingCode ?? null,
          status: status,
        })
      } else if (result.error) {
        dispatchWithAlert({
          type: 'rejected-input',
          text: inputText,
          reason: 'NOTIFICATION_NOT_FOUND',
        })
      }
    })
  }

  if (!currentResource?.id) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_LOAD')} />
  }

  if (state.view === 'details') {
    return (
      <LoadReducer.Provider dispatch={dispatch}>
        <PageHeader title={t('TITLE_LOAD_DETAILS')} onCancel={handleHome} onClear={handleReset} />
        <LoadOutputDetails state={state} />
      </LoadReducer.Provider>
    )
  }

  if (state.hasBlockingError.hasError) {
    return (
      <LoadReducer.Provider dispatch={dispatch}>
        <PageHeader title={t('TITLE_LOAD')} onCancel={handleCancel} onClear={handleReset} />
        <BlockingMessage
          title={state.hasBlockingError.title}
          message={state.hasBlockingError.message}
          handleClose={handleBlockingErrorClick}
        />
      </LoadReducer.Provider>
    )
  }

  return (
    <LoadReducer.Provider dispatch={dispatch}>
      <PageHeader title={t('TITLE_LOAD')} onCancel={handleCancel} onClear={handleReset} />
      <SectionList>
        <Section>
          <Card>
            <Form>
              <Flex.Item flex='auto'>
                <Flex flexDirection='column' margin='1.5rem 0 0 0'>
                  <ClearFieldButton onClick={handleReset} />
                </Flex>
                <Flex flex='1 1 auto' flexDirection='column'>
                  <Field label={t('FIELD_OUTPUT_CARRIER_ID')}>
                    <BarcodeInput
                      api={outputTrackingId}
                      text={state.outputText}
                      autoFocus={state.outputAccepted === null}
                      disabled={state.outputAccepted !== null}
                      onChange={text => dispatch({ type: 'change-output', text })}
                      onSubmit={handleOutputAction}
                    />
                  </Field>
                  <Field label={t('FIELD_INPUT_TRACKING_ID')}>
                    <BarcodeInput
                      api={inputTrackingId}
                      text={state.inputText}
                      autoFocus={state.outputAccepted !== null}
                      disabled={!state.outputText || state.outputRejected !== null}
                      onChange={text => dispatch({ type: 'change-input', text })}
                      onSubmit={handleInputAction}
                    />
                  </Field>
                </Flex>
              </Flex.Item>
            </Form>
          </Card>
        </Section>
        <CollapseWrapper isOpened={!!state.outputAccepted}>
          <Section>
            <Card header={<Counter count={state.inputPending.length}>{t('TITLE_VALIDATION')}</Counter>}>
              <List>
                <ListItemPending text={t('NOTIFICATION_SCAN_TRACKING_ID')} />
                {state.inputPending.map(x => (
                  <ListItemPending key={x.text} text={x.text} />
                ))}
              </List>
            </Card>
          </Section>
          <Section>
            <LoadReceipt state={state} onReset={handleReset} />
          </Section>
        </CollapseWrapper>
        <LoadRouteHistory state={state} />
      </SectionList>
    </LoadReducer.Provider>
  )
}

interface IHistoryItem {
  text: string
  message: string
  status: StatusValue
  reasonArgs?: { [key: string]: string }
}

const LoadRouteHistory = ({ state }: { state: LoadState }) => {
  const { t } = useTranslation()

  const acceptedOutput = LoadSelectors.acceptedOutput(state)
  const rejectedOutput = LoadSelectors.rejectedOutput(state)

  // reverse so that last added item becomes the first in the list
  const acceptedInputs = LoadSelectors.acceptedInputs(state)
  const rejectedInputs = LoadSelectors.rejectedInputs(state)

  const accepted: IHistoryItem[] = acceptedInputs.map(x => {
    return {
      text: x.text,
      message: 'ACCEPTED',
      status: 'good',
    }
  })
  const rejected: IHistoryItem[] = rejectedInputs.map(x => {
    return {
      text: x.text,
      message: x.reason,
      status: 'bad',
      reasonArgs: x?.reasonArgs,
    }
  })

  const inputs = [...accepted.reverse(), ...rejected.reverse()]

  const count =
    (acceptedOutput !== null ? 1 : 0) +
    (rejectedOutput !== null ? 1 : 0) +
    acceptedInputs.length +
    rejectedInputs.length

  if (count === 0) {
    return <></>
  }

  const showInputs = acceptedInputs.length > 0 || rejectedInputs.length > 0
  const showOutputs = acceptedOutput !== null || rejectedOutput !== null

  return (
    <Section>
      <Card
        header={
          <Counter countSuccess={acceptedInputs.length} countFail={rejectedInputs.length}>
            {t('TITLE_HISTORY')}
          </Counter>
        }
      >
        {showOutputs ? <ListItem primary={t('Output')} /> : null}
        {acceptedOutput !== null ? (
          <ListItemHistory text={acceptedOutput.text} message={t('ACCEPTED')} status='good' />
        ) : null}
        {rejectedOutput !== null ? (
          <ListItemHistory text={rejectedOutput.text} message={t(rejectedOutput.reason)} status='bad' />
        ) : null}

        {showInputs ? <ListItem primary={t('Input')} /> : null}
        {inputs.map(x => {
          return (
            <ListItemHistory
              key={`${x.status}-${x.text}-${x.message}`}
              text={x.text}
              message={t(x.message, x.reasonArgs)}
              status={x.status}
            />
          )
        })}
      </Card>
    </Section>
  )
}

const ListItemPending = ({ text }: { text: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>{text}</Flex.Item>
        </Flex>
      }
    />
  )
}

const ListItemHistory = ({ status, text, message }: { status: StatusValue; text: string; message: string }) => {
  return (
    <ListItem
      primary={
        <Flex gap='1rem'>
          <Flex.Item flex='1 1 auto'>
            <StatusText status={status}>{text}</StatusText>
          </Flex.Item>
          <Flex.Item flex='0 1 auto'>
            <StatusText status={status}>{message}</StatusText>
          </Flex.Item>
        </Flex>
      }
    />
  )
}
