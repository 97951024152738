import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { CircularProgress } from '@mui/material'
import { Card } from '../../mprise-light/card'
import { Flex } from '../../mprise-light/flex'
import { List, ListItem } from '../../mprise-light/list'
import { Section, SectionList } from '../../mprise-light/section'
import { StatusText } from '../../mprise-light/status-text'
import { defined } from '../../shared/typescript'
import { LoadAccepted, LoadSpec, LoadState, Selectors } from './reducer'
import { GET_CARRIER_ID_BY_FILTER } from '../../gql/carrierIds'
import { GET_POSITION } from '../../gql/positions'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { GET_ITEM } from '../../gql/item'
import { GET_WORKITEM } from '../../gql/workitems'

export const LoadOutputDetails = ({ state }: { state: LoadState }) => {
  const { t } = useTranslation()

  const [getCarrier, { loading: carrierLoading, data: carrierData }] = useLazyQuery(GET_CARRIER_ID_BY_FILTER)
  const [getWorkitem, { data: workitem }] = useLazyQuery(GET_WORKITEM)

  useEffect(() => {
    if (state?.outputAccepted) {
      getCarrier({
        variables: {
          filter: {
            id: +state.outputAccepted.carrierId,
          },
        },
      })

      getWorkitem({
        variables: {
          filter: {
            id: +state.outputAccepted.workItemId,
          },
        },
      })
    }
  }, [getCarrier, getWorkitem, state?.outputAccepted])

  const inputAccepted = state.inputAccepted
  const dedupedInputSpecs = Selectors.simplifyInputsByCombiningDuplicateItems(state.inputSpecs)

  return (
    <SectionList>
      <Section>
        <Card
          header={
            carrierLoading
              ? t('NOTIFICATION_FETCHING')
              : carrierData?.carrierId?.code ?? `${t('NOTIFICATION_NOT_FOUND')}`
          }
        >
          <List>
            <ListItem
              primary={t('FIELD_WORK_ITEM_NUMBER')}
              secondary={workitem ? workitem.workItem.number : t(`PLACEHOLDER_NOT_SET`)}
            />
            <ListItem
              primary={t('FIELD_CUSTOMER_NAME')}
              secondary={carrierData ? carrierData.carrierId.customerName : t(`PLACEHOLDER_NO_CUSTOMER_NAME`)}
            />
            <ListItem
              primary={t('FIELD_CARRIER_ID_STATUS')}
              secondary={
                carrierData
                  ? carrierData.carrierId.status
                    ? t(`CARRIER_STATUS.${carrierData.carrierId.status}`)
                    : null
                  : t('PLACEHOLDER_NOT_SET')
              }
            />
          </List>
        </Card>
      </Section>
      {dedupedInputSpecs.map((s, i) => (
        <Section key={i}>
          <LoadOutputDetailsSpec
            spec={s}
            input={inputAccepted.filter(
              x =>
                x.itemId === s.itemId &&
                x.variantCode === s.variantCode &&
                x.warehouseTrackingCode === s.warehouseTrackingCode,
            )}
          />
        </Section>
      ))}
    </SectionList>
  )
}

const LoadOutputDetailsSpec = ({ spec, input }: { spec: LoadSpec; input: Array<LoadAccepted> }) => {
  const { t } = useTranslation()

  const [getItem, { loading: itemLoading, data: item }] = useLazyQuery(GET_ITEM)
  const [getPosition, { data: position }] = useLazyQuery(GET_POSITION)

  useEffect(() => {
    if (spec) {
      getItem({
        variables: {
          filter: {
            id: spec.itemId,
          },
        },
      })

      getPosition({
        variables: {
          filter: {
            id: +spec.positionId!,
          },
        },
      })
    }
  }, [getItem, getPosition, spec])

  return (
    <Card
      header={
        <Flex>
          <Flex.Item flex='1 1 auto'>
            <StatusText.ExactCount current={input.reduce((acc, n) => acc + n.quantity, 0)} expected={spec.quantity}>
              {itemLoading
                ? [t('NOTIFICATION_FETCHING')]
                : [
                    item ? item.item.name : t('NOTIFICATION_NOT_FOUND'),
                    item && item.item.code,
                    spec.variantCode,
                    spec.warehouseTrackingCode,
                  ]
                    .filter(defined)
                    .join(`- `)}
              <div>
                <StatusText status='neutral'>{position?.position.code}</StatusText>
              </div>
            </StatusText.ExactCount>
          </Flex.Item>
          <Flex.Item flex='0 0 auto'>
            <StatusText.ExactCount current={input.reduce((acc, n) => acc + n.quantity, 0)} expected={spec.quantity}>
              {spec.quantity} {spec.quantityUnit}
            </StatusText.ExactCount>
          </Flex.Item>
        </Flex>
      }
    >
      <List>
        {input
          .map(x => input.find(y => y.trackingId === x.trackingId))
          .filter(defined)
          .map(x => (
            <LoadOutputDetailsRow key={x.trackingId} input={x} />
          ))}
        {input.length ? null : <Flex.Item flex='0 0 auto'>{t('NOTIFICATION_NONE_ALLOCATED')}</Flex.Item>}
      </List>
    </Card>
  )
}

const LoadOutputDetailsRow = ({ input }: { input: LoadAccepted }) => {
  const { t } = useTranslation()

  const [getTrid, { data, loading, error }] = useLazyQuery(GET_TRACKING_ID)

  useEffect(() => {
    if (input) {
      getTrid({
        variables: {
          filter: {
            id: input.trackingId,
          },
        },
      })
    }
  }, [])

  let content
  if (loading) {
    content = <CircularProgress color='inherit' size={18} />
  } else if (error) {
    content = <StatusText status='bad'>{t('NOTIFICATION_ERROR_LOADING_TRACKINGID')}</StatusText>
  } else {
    content = data?.trackingId.code
  }

  return <ListItem primary={content} />
}
